import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                </div>

                <div className="section-main-description">
                  <p id="bold">
                  The Welcome Home Fund is the main community impact project of the Muncey Group and a 100% philanthropic fund. This fund addresses the racial wealth gap  by giving $20,000 grants to first-generation homebuyers of color (primarily single mothers from Boston).                  
                  </p>

                  <p id="bold">
                  During Phase One, five families were selected and each given $20,000 to help make their homeownership dreams a reality. With support from the fund, five of Greater Boston’s families — led by single mothers of color — are now able to change their generational wealth for themselves and their children.
                  </p>

                  <p>
                  Since 2022, the Muncey Group has been working with the nonprofit <a href="https://www.compassworkingcapital.org/">Compass Working Capital</a> (which has no relationship to the Compass brokerage!) as its partner for the fund. Compass Working Capital runs a nationally-recognized program that supports families with low income to build assets and financial skills as a pathway out of poverty.                  
                  </p>

                  <p>
                  Compass Working Capital selected the families that were the best fit for grants to help them with a down payment on a home and the Muncey Group provided the funds --- no strings attached.
                  </p>

                  <p id="bold">
                  Now, the Muncey Group is going to be helping five more families with grants for down payments over the next year. Join us by donating!                  
                  </p>
                  
                  <p><i>
                  Most people get a little help along their path to buying their first home. Let’s pay it forward and help those who will be first-generation homeowners realize their dream.
                  </i>
                  </p>

                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
